import React, {Component} from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import './style.css';

// example: https://react-day-picker.js.org/examples/input-state
/**
 * ExampleComponent is an example component.
 * It takes a property, `label`, and
 * displays it.
 * It renders an input with the property `value`
 * which is editable by the user.
 */

const currentYear = new Date().getFullYear();
const fromMonth = new Date(2019, 10);
const toMonth = new Date(currentYear, 11);

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
      days.push(
        moment(weekStart)
          .add(i, 'days')
          .toDate()
      );
    }
    return days;
  }
  
  function getWeekRange(date) {
    return {
      from: moment(date)
        .startOf('isoWeek')
        .toDate(),
      to: moment(date)
        .endOf('isoWeek')
        .toDate(),
    };
  }

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

export default class weekPicker extends Component {
    constructor(props) {
        super(props);

        this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.handleDayEnter = this.handleDayEnter.bind(this);
        this.handleDayLeave = this.handleDayLeave.bind(this);
        this.handleWeekClick = this.handleWeekClick.bind(this);

        this.state = {};

        this.state.month = new Date(props.selectedDay);
        this.state.hoverRange = undefined;

    }

    handleDayChange(date,  modifiers = {}) {
        if (modifiers.disabled) {
          return;
        }
        this.props.setProps({
          selectedDays: modifiers.selected ? [] : getWeekDays(getWeekRange(date).from),
          selectedDay: modifiers.selected ? undefined : date,
      });
    };

    handleDayEnter(date){
        this.setState({
        hoverRange: getWeekRange(date),
        });
    };

    handleDayLeave(){
        this.setState({
        hoverRange: undefined,
        });
    };

    handleWeekClick(weekNumber, days, e, modifiers = {}){
        var i;
        
        for(i = 0; i < days.length; i++)
        {
          if(days[i] > this.props.lastDate || days[i] < this.props.firstDate)
          {
            this.props.setProps({
              selectedDays: [],
              selectedDay: undefined,
            });
            return;
          }
        }
        this.props.setProps({
          selectedDays: days,
          selectedDay: days[0],
        });
        
    };

    handleYearMonthChange(month) {
        this.setState({
          month: month
        });
        this.props.setProps({
          selectedDays: [],
          selectedDay: month
        });
    }
    
    render() {
        const {id, setProps, selectedDays, selectedDay, firstDate, lastDate} = this.props;
        const hoverRange = this.state.hoverRange;
        const daysAreSelected = selectedDays.length > 0;
        const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: this.state.hoverRange && this.state.hoverRange.from,
        hoverRangeEnd: this.state.hoverRange && this.state.hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
        };

        return (
            <div className="SelectedWeekExample">
            <DayPicker
                selectedDays={new Date(selectedDay)}
                showWeekNumbers
                showOutsideDays 
                firstDayOfWeek = {1}
                enableOutsideDaysClick
                onDayClick={this.handleDayChange}
                onDayMouseEnter={this.handleDayEnter}
                onDayMouseLeave={this.handleDayLeave}
                onWeekClick={this.handleWeekClick}
                onMonthChange={this.handleYearMonthChange}
                month={this.state.month}
                fromMonth={fromMonth}
                toMonth={toMonth}
                disabledDays={[
                                {
                                  before: firstDate,
                                },
                                {
                                  after: lastDate,
                                }
                              ]}
                modifiers={modifiers}
                captionElement={({ localeUtils }) => (
                    <YearMonthForm
                    date={new Date(selectedDay)}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChange}
                    />
                )}
            />
            {selectedDays.length === 7 && (
                <div>
                    {moment(selectedDays[0]).format('LL')} –{' '}
                    {moment(selectedDays[6]).format('LL')}
                </div>
            )}
            </div>
        );
    }
}

weekPicker.defaultProps = {
  selectedDay: new Date( new Date().getFullYear(),new Date().getMonth(),new Date().getDate() ),
  selectedDays: [],
  firstDate: new Date(1970, 1, 1),
  lastDate: new Date(2070, 1, 1),
};

weekPicker.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    selectedDays: PropTypes.array, 
    selectedDay: PropTypes.oneOfType([
                    PropTypes.object,
                    PropTypes.string
    ]),
    firstDate: PropTypes.object,
    lastDate: PropTypes.object,
    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};
